import React from "react"
import { AppFunctionComponent } from "../../../types"
import styled from "styled-components"
import Section, {
  SectionArticle,
  SectionHeading,
  SectionLayout,
} from "../../../components/common/sections/section.component"
import { PrimaryHeading } from "../../../components/common/heading.component"
import NotFoundPageIllustration from "../../../images/not-found-page-illustration.inline.svg"
import { BasicButtonLink, RawLink } from "../link/link.component"
import { Path } from "../../../navigation/constants/routes"
import useTranslationHook from "../../../hooks/use-translation.hook"

const NotFoundSection = styled(Section)`
  --text-max-width: 560px;

  ${SectionHeading} {
    margin-bottom: 30px;

    ${PrimaryHeading} {
      text-align: center;
      margin-bottom: 30px;

      @media (max-width: ${({ theme }) =>
          theme.breakpoint.tabletHorizontal}px) {
        margin-top: 50px;
      }
    }
  }

  svg {
    width: 100%;
    height: auto;
  }

  ${SectionArticle} {
    text-align: center;
  }
`

const NotFoundPage: AppFunctionComponent = () => {
  const { translate } = useTranslationHook()
  return (
    <NotFoundSection layout={SectionLayout.Text}>
      <SectionHeading>
        <PrimaryHeading>{translate("404-silence-note")}</PrimaryHeading>
        <NotFoundPageIllustration />
      </SectionHeading>
      <SectionArticle>
        <p>
          {translate("404-stay-still-help")}{" "}
          <RawLink to={Path.Contact}>{translate("404-contact-us")}</RawLink>{" "}
          {translate("404-back-homepage")}
        </p>
        <BasicButtonLink to={Path.Home}>
          {translate("404-back-button")}
        </BasicButtonLink>
      </SectionArticle>
    </NotFoundSection>
  )
}

export default NotFoundPage
