import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo.component"
import { Menu } from "../../navigation/types/menu.interface"
import { AppFunctionComponent, TextBuilder } from "../../types"
import NotFoundPageContent from "../../ui/components/not-found-page/not-found-page.component"

export type NotFoundProps = {
  data: {
    topNavigationContent: Menu
    footerNavigationContent: Menu
    promobarContent: TextBuilder
  }
}

const NotFoundPage: AppFunctionComponent<NotFoundProps> = ({
  data: {
    topNavigationContent: { navigationItems: topNavigationItems },
    footerNavigationContent: { navigationItems: footerNavigationItems },
    promobarContent,
  },
}) => (
  <Layout
    promobarContent={promobarContent}
    topNavigationItems={topNavigationItems}
    footerNavigationItems={footerNavigationItems}
  >
    <SEO title="404: Nie znaleziono" />
    <NotFoundPageContent />
  </Layout>
)

export const query = graphql`
  query NotFoundQueryPL($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    topNavigationContent: contentfulNavigation(
      contentfulid: { eq: "top-navigation" }
      node_locale: { eq: "pl-PL" }
    ) {
      ...Navigation
      node_locale
    }
    footerNavigationContent: contentfulNavigation(
      contentfulid: { eq: "footer-navigation" }
      node_locale: { eq: "pl-PL" }
    ) {
      ...Navigation
      node_locale
    }
    promobarContent: contentfulText(
      contentful_id: { eq: "63Ao7XUYTc7UtDipcRpwoc" }
      node_locale: { eq: "pl-PL" }
    ) {
      text {
        raw
      }
      node_locale
    }
  }
`

export default NotFoundPage
